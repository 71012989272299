<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="#fafafa"
    outlined
    flat
    height="5"
    style=""
  >
  </v-app-bar>
</template>

<script>
// Components

// Utilities

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({}),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters("auth", ["strapiUserData"]),
    ...mapGetters("app", ["allDataLoaded"])
  },
  async created() {
    await this.refreshUserData();
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    ...mapActions("auth", ["refreshUserData"]),
    ...mapActions("app", ["setAllDataLoaded"])
  }
};
</script>

<style lang="scss" scoped>
#app-bar {
  backdrop-filter: blur(40px);
}
</style>
